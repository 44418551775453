import { persistReducer as createPersistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";

export const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["data"], // navigation will not be persisted
};

const authPersistConfig = {
  key: "auth",
  storage,
};

export default function persistReducer(shouldPersist?: boolean) {
  return shouldPersist
    ? createPersistReducer(authPersistConfig, authReducer)
    : authReducer;
}
