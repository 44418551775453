import api from "core/model/api";
import {
  setAuthAccessor,
  setTokenAccessor,
  setTokenExpiration,
} from "core/model/api/transport";
import { getTopLevelDomain } from "core/model/utils/urls";
import { DispatchAction } from "core/types";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import persistReducer, {
  rootPersistConfig,
} from "reduxentities/store/persistReducer";
import settingsSlice from "./slices/settingsSlice";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

function getReducers(shouldPersist?: boolean) {
  return {
    auth: persistReducer(shouldPersist),
    settings: settingsSlice,
  };
}

export function setupStore(shouldPersist: boolean) {
  // Define middlewares
  const Middlewares = [thunk];

  const combinedReducers = shouldPersist
    ? persistCombineReducers<AnyObject, DispatchAction>(
        rootPersistConfig,
        getReducers(true),
      )
    : combineReducers<AnyObject>(getReducers());

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Create Store
  const store = createStore(
    combinedReducers as any,
    {
      settings: {
        // LANG is statically defined in webpack for test envs
        // Top level domain should be used in preprod and prod envs
        language: process.env.LANG || getTopLevelDomain().toLowerCase(),
      },
    },
    composeEnhancers(applyMiddleware(...Middlewares)),
  );

  let persistor;
  if (shouldPersist) {
    persistor = persistStore(store, null);
  }

  const setTokenFn = () => {
    return (store.getState() as AnyObject).auth.credentials.token;
  };

  api.setTokenAccessor(setTokenFn);
  api.setAuthAccessor(
    () => (store.getState() as AnyObject).auth.identification,
  );
  api.setTokenExpiration(
    () => (store.getState() as AnyObject).auth.credentials.expiration,
  );

  setTokenAccessor(setTokenFn);
  setAuthAccessor(() => (store.getState() as AnyObject).auth.identification);
  setTokenExpiration(
    () => (store.getState() as AnyObject).auth.credentials.expiration,
  );

  return { store, persistor };
}
