// see https://github.com/nextui-org/nextui/blob/142f394cb5d25c97c919782d03f74c3b310eab0c/packages/components/spinner/src/use-spinner.ts
import { SlotsToClasses } from "@heroui/react";
import { clsx, objectToDeps } from "@heroui/shared-utils";
import type { HTMLHeroUIProps, PropGetter } from "@heroui/system-rsc";
import { mapPropsVariants } from "@heroui/system-rsc";
import { Ref, useCallback, useMemo } from "react";
import { useTranslations } from "translations";
import { SpinnerSlots, SpinnerVariantProps, spinner } from "./theme";

interface Props extends HTMLHeroUIProps<"div"> {
  /**
   * Classname or List of classes to change the classNames of the element.
   * if `className` is passed, it will be added to the base slot.
   *
   * @example
   * ```ts
   * <Spinner classNames={{
   *    base:"base-classes",
   *    wrapper: "wrapper-classes",
   *    loader: "loader-classes",
   *    label: "label-classes"
   * }} />
   * ```
   */
  classNames?: SlotsToClasses<SpinnerSlots>;
  /**
   * Spinner label, in case you passed it will be used as `aria-label`.
   */
  label?: string;
  /**
   * Ref to the DOM node.
   */
  ref?: Ref<HTMLElement | null>;
}

export type UseSpinnerProps = Props & SpinnerVariantProps;

export function useSpinner(originalProps: UseSpinnerProps) {
  const translations = useTranslations();
  const [props, variantProps] = mapPropsVariants(
    originalProps,
    spinner.variantKeys,
  );

  const {
    children,
    className,
    classNames,
    label: labelProp,
    ...otherProps
  } = props;

  const slots = useMemo(
    () => spinner({ ...variantProps }),
    [objectToDeps(variantProps)],
  );

  const baseStyles = clsx(classNames?.base, className);

  const label = labelProp || children;

  const ariaLabel = useMemo(() => {
    if (label && typeof label === "string") {
      return label;
    }

    return !otherProps["aria-label"] ? translations.general.loading : "";
  }, [children, label, otherProps["aria-label"]]);

  const getSpinnerProps = useCallback<PropGetter>(
    () => ({
      "aria-label": ariaLabel,
      className: slots.base({
        class: baseStyles,
      }),
      ...otherProps,
    }),
    [ariaLabel, slots, baseStyles, otherProps],
  );

  return { label, slots, classNames, getSpinnerProps };
}

export type UseSpinnerReturn = ReturnType<typeof useSpinner>;
