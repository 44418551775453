import { datadogLogs } from "@datadog/browser-logs";
import { clearEncryptionCaches } from "apollo/encryption/caches";
import { TRACK_EVENTS } from "core/consts";
import auth from "core/model/api/endpoints/auth";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";
import { LOGGED_OUT } from "./store/slices/authSlice";
import { LANGUAGE_CHANGED } from "./store/slices/settingsSlice";

export function useOnLogout() {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();

  return useCallback(
    async (options?: { hardRefresh?: boolean; tokenExpired?: boolean }) => {
      let ssoRedirect;

      if (!options?.tokenExpired) {
        try {
          const response = await auth.logout();
          if (response?.sso_logout_url) {
            const url = new URL(response.sso_logout_url);
            // Redirect to the origin to allow the default route decide where to go
            url.searchParams.append(
              "post_logout_redirect_uri",
              window.location.origin,
            );
            ssoRedirect = url.href;
          }
        } catch (e) {
          console.error(`Logout failed: ${e}`);
        }
      }

      trackEvent({
        name: TRACK_EVENTS.LOGGED_OUT,
        type: "connect",
        token_expired: options?.tokenExpired,
        forced: options?.hardRefresh,
      });

      datadogLogs.clearUser();

      clearEncryptionCaches();
      // We will be immediately redirecting to the fusionAuth logout screen so
      // there's no need to make the UI react to the lack of login anymore. With
      // this we prevent the small flicker of the login screen that can be seen
      // before the redirection to fusionAuth
      if (!ssoRedirect) {
        dispatch(LOGGED_OUT());
      }
      if (options?.hardRefresh) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        if (!ssoRedirect) {
          window.location.reload();
        }
      }

      if (ssoRedirect) {
        window.location.replace(ssoRedirect);
      }
    },
    [],
  );
}

export function useLanguageChange() {
  const dispatch = useDispatch();

  return useCallback(
    (language: string) => dispatch(LANGUAGE_CHANGED(language)),
    [],
  );
}
