import { useGetVersions } from "apollo/hooks/queries";
import { useEnvContext } from "context/EnvContext";
import {
  APP_VERSION_NAMES,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "core/consts";
import Config, { ENV_DEVELOPMENT } from "core/model/config";
import {
  LOCAL_STORAGE_KEYS,
  getObjectLocalStorage,
  setObjectLocalStorage,
} from "core/model/localStorage";
import { isProd, isStoryshots } from "core/model/utils/featureFlags";
import { addDays, isAfter } from "date-fns";
import { RefreshBannerVersionChecker } from "dsl/atoms/RefreshBanners";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { selectVersion } from "reduxentities/selectors";
import { LOGGED_OUT } from "reduxentities/store/slices/authSlice";

type VersionCheckerLocalStorage = {
  dismissedUntilDate: number;
};

/**
 * The initial value is explicitly set to null,
 * because it needs to be different from the (possibly) undefined value returned from localStorage.
 */
const INITIAL_LOCAL_STORAGE_VALUE = null;
const DISMISSAL_PERIOD_DAYS = 30;

export default function VersionChecker() {
  const { app } = useEnvContext();
  const [queryProgress, versions] = useGetVersions({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const reduxVersion = useSelector(selectVersion);
  const codeVersion = Config.version;
  const appVersion = versions?.find(
    ({ application }) => application === APP_VERSION_NAMES[app],
  );
  const [versionCheckerLocalStorage, setVersionCheckerLocalStorage] = useState<
    VersionCheckerLocalStorage | typeof INITIAL_LOCAL_STORAGE_VALUE | undefined
  >(INITIAL_LOCAL_STORAGE_VALUE);

  const hasNotBeenDismissed =
    !isProd &&
    versionCheckerLocalStorage !== INITIAL_LOCAL_STORAGE_VALUE &&
    !(
      versionCheckerLocalStorage?.dismissedUntilDate &&
      isAfter(
        versionCheckerLocalStorage.dismissedUntilDate,
        new Date().getTime(),
      )
    );
  const appAndCodeVersionsAreIdentical =
    codeVersion && appVersion?.version && appVersion.version !== codeVersion;

  const isPr = process.env.PR_NAME;

  function handleDismiss() {
    const updatedVersionCheckerLocalStorage = {
      dismissedUntilDate: addDays(new Date(), DISMISSAL_PERIOD_DAYS).getTime(),
    };
    setVersionCheckerLocalStorage(updatedVersionCheckerLocalStorage);
    setObjectLocalStorage<VersionCheckerLocalStorage>(
      LOCAL_STORAGE_KEYS.VERSION_CHECKER,
      updatedVersionCheckerLocalStorage,
    );
  }

  useEffect(() => {
    const localStorageValue = getObjectLocalStorage<VersionCheckerLocalStorage>(
      LOCAL_STORAGE_KEYS.VERSION_CHECKER,
    );

    setVersionCheckerLocalStorage(localStorageValue);
  }, []);

  useEffect(() => {
    if (Config.environment === ENV_DEVELOPMENT) return;
    if (queryProgress !== QUERY_PROGRESS_SUCCEED) return;

    if (!codeVersion) {
      trackEvent({
        system_event: true,
        name: TRACK_EVENTS.VERSION_CONTROL,
        difference: "local_no_version",
        code_version: codeVersion,
      });

      return;
    }
    if (!reduxVersion) {
      trackEvent({
        name: TRACK_EVENTS.VERSION_CONTROL,
        difference: "redux_no_version",
        code_version: codeVersion,
        system_event: true,
      });

      dispatch(LOGGED_OUT());
      return;
    }

    if (!appVersion) {
      trackEvent({
        name: TRACK_EVENTS.VERSION_CONTROL,
        difference: "backend_error",
        versions,
        redux_version: reduxVersion,
        system_event: true,
        code_version: codeVersion,
      });

      return;
    }

    // We should do something for that later
    if (appVersion.version !== codeVersion) {
      trackEvent({
        name: TRACK_EVENTS.VERSION_CONTROL,
        difference: "user_old_version",
        backend_version: appVersion.version,
        redux_version: reduxVersion,
        system_event: true,
        code_version: codeVersion,
      });
    }
  }, [queryProgress]);

  if (
    hasNotBeenDismissed &&
    appAndCodeVersionsAreIdentical &&
    !isStoryshots &&
    !isPr
  ) {
    return <RefreshBannerVersionChecker onDismiss={handleDismiss} />;
  }

  return null;
}
